import Error from 'next/error';
import LayoutNotFound from '/components/LayoutNotFound';

const NotFoundPage = ({ errorCode }) => {
  if (errorCode) {
    return <Error statusCode={errorCode} />;
  }
  return (
    <LayoutNotFound />
  );
};

export default NotFoundPage;
